 <template>
 <div class="bg-black">
   <div class="heading  py-5  px-5" id="heading">Trolley</div>
   </div>

   <section class="bg-zinc-900">
   <div class="grid lg:pt-7 lg:grid-cols-2">
   
   <div class=" grid my-auto lg:mx-5 ">
    <img src="@/assets/trollies/20010.webp" class="w-screen lg:rounded-lg" alt="VS6 19980">
    <span class="relative bottom-10 left-4 text-xs text-white">VS6 20010</span>
    </div>

<div class="grid  grid-cols-2 md:gap-x-5 md:gap-y-2 md:mx-6 -mt-2 gap-x-4 mx-3 pt-5 lg:pt-0 justify-center items-center  lg:mx-auto">
    <div   class="flex relative flex-col -mb-3" >
    <img src="@/assets/trollies/20011.webp" class="rounded-lg shadow-sm p-5 shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS 20011</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Bar Table Taper</span>
</div>
    </div>


    <div   class="flex  flex-col lg:mr-5 -mb-6" >

    <div class="relative">
    <img src="@/assets/trollies/20012.webp" class="rounded-lg h-1/2 shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS 20012</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0">
    <span class="text-xs relative lg:bottom-10  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Snack Trolley</span>
</div></div>


<div class="relative">
<img src="@/assets/trollies/20013.webp" class="rounded-lg h-1/2 shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS 20013</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0">
    <span class="text-xs relative lg:bottom-10  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Multipurpose Trolley</span>
</div>
    </div>
    </div>
    
    </div>



   </div>
   
   </section>
 </template>
 

 
 <style scoped>

 </style>   



<script>

export default {
    data(){
        return{
            
        }
    }
}
</script>
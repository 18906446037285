<template>
<h1 class="text-5xl">Page Not Found. Error 404.</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>
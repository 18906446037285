 <template>
  <div class="bg-black">
   <div class="heading text-6xl py-5  px-5" id="heading">Tableware</div>
   </div>

   <main class="overflow-hidden">



   <!-- first section  -->
   <section class="grid lg:mt-7 lg:grid-cols-2">
   
   <div class=" grid my-auto lg:mx-5 lg:mt-5 ">
    <img src="@/assets/tableware/cover.webp" class="w-screen lg:rounded-lg" alt="VS6 19980">
    </div>

<div class="grid pt-10 grid-cols-2 md:gap-x-5 md:gap-y-2 md:mx-6 -mt-2 gap-x-4 mx-3  lg:pt-0 justify-center items-center  lg:mx-auto">
    <div   class="flex flex-col relative -mb-3" >
    <img src="@/assets/tableware/20044.webp" class="rounded-lg shadow-sm p-5 shadow-slate-900" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20044</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >2 Tier Stand</span>
</div>
    </div>


    <div   class="flex flex-col lg:mr-5  -mb-3" >
    <div class="relative my-2">
    <img src="@/assets/tableware/20014.webp" class="rounded-lg h-1/2 p-7   shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20014/15</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0">
    <span class="text-xs relative lg:bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Hexa Serving Bowl</span>
</div>
</div>


    <div class="relative my-2">
<img src="@/assets/tableware/20016.webp" class="rounded-lg h-1/2 p-7 -mt-5 lg:-mt-3 shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20016/17</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative bottom-4 lg:bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Round Serving Bowl</span>
</div></div>
    </div>
    
    </div>



   </section>



<!-- second section  -->
    <section class="grid lg:-mt-2 lg:-mb-5 pb-5 lg:py-5  lg:mx-5 lg:grid-cols-2">
   <div class="grid  grid-cols-2 md:gap-x-5 md:gap-y-2 md:mx-6 -mt-2 gap-x-4 mx-3 pt-5 lg:pt-0 justify-center items-center  lg:mx-auto">



   <div   class="flex flex-col -mb-3 relative" >
    <img src="@/assets/tableware/20045.webp" class="rounded-lg shadow-sm p-5 shadow-slate-900" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20045</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Water Jug</span>
</div>
    </div>
    


    <div   class="flex flex-col lg:mr-2.5 -mb-3" >

    <div class="relative mb-2 lg:my-0">
    <img src="@/assets/tableware/20027.webp" class="rounded-lg h-1/2 p-7   shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20027</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Serving Set</span>
    
</div>
</div>


<div class="relative mt-2 lg:mt-0">
<img src="@/assets/tableware/20028.webp" class="rounded-lg h-1/2 -mt-5 lg:-mt-3 p-7 shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20028</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-11 bottom-5 inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Sugar Sachet Holder</span>
</div>
</div>
    </div>
    
    </div>

<div class="grid  grid-cols-2 md:gap-x-5 md:gap-y-2 md:mx-6 -mt-2 gap-x-4 mx-3 pt-5 lg:pt-0 justify-center items-center  lg:mx-auto">
    


    <div   class="flex flex-col lg:ml-2.5 -mb-3" >

    <div class="relative mb-2 lg:mb-2">
    <img src="@/assets/tableware/20029.webp" class="rounded-lg h-1/2 p-7  shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20029</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Napkin Ring</span>
</div>
</div>


    <div class="relative mb-2 lg:mb-2">

<img src="@/assets/tableware/20030.webp" class="rounded-lg h-1/2 p-7 -mt-5 lg:-mt-3 shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20030</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-11 bottom-5  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Charger Plate</span>
</div>
</div>
    </div>


    <div   class="flex flex-col relative -mb-3" >
    <img src="@/assets/tableware/20043.webp" class="rounded-lg shadow-sm p-5 shadow-slate-900" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20043</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >3 Tier Stand</span>
</div>
    </div>
    
    </div>



   </section>






<!-- third section  -->
<section class="grid lg:mt-7 lg:grid-cols-2">
   
   <div class=" lg:hidden grid my-auto relative lg:mx-5 -mb-7">
    <img src="@/assets/tableware/20022.webp" class="w-screen lg:rounded-lg" alt="VS6 19980">
    <span class="relative bottom-10 left-4 text-xs text-white">VS6 20022/23/24</span>
    <div class="flex items-end  justify-end">
    <span class="text-[13px]  text-white relative bottom-14 right-4  " >Serving Handi</span>
</div>
    </div>

<div class="grid lg:ml-5  grid-cols-2 md:gap-x-5 md:gap-y-2 md:mx-6 py-7 -mt-2 gap-x-4 mx-3 lg:pt-0 justify-center items-center  lg:mx-auto">
    <div   class="flex flex-col relative -mb-3" >
    <img src="@/assets/tableware/20035.webp" class="rounded-lg shadow-sm p-5 shadow-slate-900" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20035</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Card Holder</span>
</div>
    </div>


    <div   class="flex flex-col  -mb-3" >

    <div class="relative flex flex-col  ">
    <img src="@/assets/tableware/20033.webp" class="rounded-lg h-1/2 p-5 shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20033</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Salt & Pepper set</span>
</div></div>


    <div   class="flex flex-col relative mt-3 -mb-3" >

<img src="@/assets/tableware/20034.webp" class="rounded-lg h-1/2 p-5 -mt-5 shadow-sm shadow-slate-900" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20034</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-11 bottom-5  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Ice Cream Cup</span>
</div>
</div>
    </div>
    
    </div>

    <div class=" hidden lg:grid my-auto lg:mx-5 lg:mt-3">
    <img src="@/assets/tableware/20022.webp" class="w-screen lg:rounded-lg" alt="VS6 19980">
    <span class="relative bottom-10 left-4 text-xs text-white">VS6 20022/23/24</span>
    <div class="flex items-end  justify-end">
    <span class="text-[13px]  text-white relative bottom-14 right-4  " >Serving Handi</span>
</div>
    </div>



   </section>



<!-- 4th section  -->
<section class="grid grid-cols-2 lg:grid-cols-3 gap-x-4 pb-5  lg:-gap-y-5 lg:-mb-16 lg:-mt-3 lg:mx-5 mx-3 -mb-5 -mt-2 ">
<div class="">
<img src="@/assets/tableware/20040.webp" class="rounded-lg  p-5 shadow-sm shadow-slate-900">
<span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20040</span>
    <div class="flex items-end justify-end">
    <span class="text-xs relative bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Napkin Holder</span>
</div>
</div>


<div class="grid grid-cols-2 gap-x-3 ">
<div class="rounded-lg text-xs flex flex-col justify-center bg-white  shadow-sm h-fit shadow-slate-900">
<img src="@/assets/tableware/20031.webp" class="rounded-lg" alt="">
<span class="text-center -mt-2 font-semibold">VS6 20031</span>
<span class="text-center -mt-0.5 font-semibold">Salt & Pepper Set</span>

</div>
<div class="rounded-lg text-xs flex flex-col justify-center bg-white shadow-sm h-fit shadow-slate-900">
<img src="@/assets/tableware/20032.webp" class="rounded-lg" alt="">
<span class="text-center -mt-2 font-semibold">VS6 20032</span>
<span class="text-center -mt-0.5 font-semibold">Salt & Pepper Set</span>

</div>

 
</div>


<div class="">
<img src="@/assets/tableware/20042.webp" class="rounded-lg  p-5 shadow-sm shadow-slate-900">
<span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20042</span>
    <div class="flex items-end justify-end">
    <span class="text-xs relative bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Serving Set</span>
</div>
</div>




<div class="">
<img src="@/assets/tableware/20036.webp" class="rounded-lg  p-5 shadow-sm shadow-slate-900">
<span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20036</span>
    <div class="flex items-end justify-end">
    <span class="text-xs relative bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Card Holder</span>
</div>
</div>



<div class="grid grid-cols-2 gap-x-3 ">
<div class="rounded-lg text-xs flex flex-col justify-center bg-white shadow-sm h-fit shadow-slate-900">
<img src="@/assets/tableware/20038.webp" class="rounded-lg"  alt="">
<span class="text-center -mt-2 font-semibold">VS6 20038</span>
<span class="text-center -mt-0.5 font-semibold">Straw Holder</span>

</div>
<div class="rounded-lg text-xs flex flex-col justify-center bg-white shadow-sm h-fit shadow-slate-900">
<img src="@/assets/tableware/20037.webp" class="rounded-lg" alt="">
<span class="text-center -mt-2 font-semibold">VS6 20037</span>
<span class="text-center -mt-0.5 font-semibold">Toothpick Holder</span>

</div>

 
</div>



<div class="">
<img src="@/assets/tableware/20041.webp" class="rounded-lg  p-5 shadow-sm shadow-slate-900">
<span class="text-xs relative bottom-6   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20041</span>
    <div class="flex items-end justify-end">
    <span class="text-xs relative bottom-11  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Ice Cream Cup</span>
</div>
</div>
</section>


<!-- last section  -->
   <section class="lg:flex lg:w-screen first lg:pt-7 lg:px-5 lg:pb-5">
    <div class=" grid justify-center items-center my-auto  lg:mr-5 lg:w-1/2">
    <img src="@/assets/tableware/hexagonal.webp" class="w-screen lg:shadow-md lg:shadow-slate-900 lg:rounded-lg" alt="hexagonal">
    </div>

    <div class="grid lg:w-1/2 grid-cols-2 md:gap-x-5  md:mx-6 lg:mr-5  gap-x-4 mx-3 mt-5 lg:py-0 items-center justify-center  lg:mx-auto">
    <div  v-for="pic in tableware.slice(0,4)" class="flex flex-col relative my-2 lg:my-0 lg:-mb-5 -mb-3 h-fit" :key="pic.id">
    <img :src="require(`@/assets/tableware/${pic.img}.webp`)" class="rounded-lg shadow-sm p-5  shadow-slate-900" :alt="pic.id">
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 {{pic.img}}</span>
    <div class="flex items-end justify-end absolute lg:static right-0 top-0">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >{{pic.text}}</span>
</div>
    </div>
    
    </div>



    
    </section>





   
   </main>
 </template>
 

 
 <style >


 </style>   


<script>

export default {
    data(){
        return{
            tableware: [
                {
                    img:'20018',
                    text: 'Rect. Serving Bowl'
                },
                 {
                    img:'20020',
                    text: 'Oval Serving Bowl'
                },
                 {
                    img:'20025',
                    text: 'Bread Basket'
                },
                 {
                    img:'20026',
                    text: 'Napkin Holder'
                },

            ]
        }
    }
}
</script>
<template>


 <div class="bg-black">
   <div class="heading text-6xl py-5  px-5" id="heading">Chafing Dishes</div>
   </div>

  <main>
    <!-- section 1 on phone -->
    <section class="lg:hidden pt-5 -mb-3 first ">
      <div class="grid justify-center items-center my-auto">
        <img
          src="@/assets/chafing/30.webp"
          class="w-screen lg:shadow-md lg:shadow-slate-900 lg:rounded-lg"
          alt="cover"
        />
      </div>

      <div
        class="
          grid
          
          grid-cols-2
          md:gap-x-5 md:mx-6
          
          gap-x-4
          mx-3
          mt-5
          lg:py-0
          items-center
          justify-center
          lg:mx-auto
        "
      >
        <div class="relative">
          <img
            src="@/assets/chafing/20073.webp"
            alt="20111"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />

          <span
            class="
              text-xs
              relative
              bottom-6
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20073</span
          >
          <div class="flex absolute top-0 right-0 items-end justify-end">
            <span
              class="
                text-xs
                relative
                
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Tiara Chafing Dish 6Ltrs</span
            >
          </div>
        </div>

        <div class="relative">
          <img
            src="@/assets/chafing/20074.webp"
            alt="20113"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />

          <span
            class="
              text-xs
              relative
              bottom-6
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20074</span
          >
          <div class="flex items-end absolute top-0 right-0 justify-end">
            <span
              class="
                text-xs
                relative
                
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Tiara Chafing Dish 8Ltrs</span
            >
          </div>
        </div>
      </div>
    </section>

    <!-- section 1 on pc  -->
    <section class="lg:grid grid-cols-4 pt-10 gap-x-5 px-5 hidden">
      <div class="col-span-1 h-fit -mb-9">
        <img
          src="@/assets/chafing/20073.webp"
          alt="20111"
          class="rounded-lg shadow-sm p-5 shadow-neutral-500"
        />

        <span
          class="
            text-xs
            relative
            bottom-6
            rounded-tr-md rounded-bl-md
            bg-opacity-30
            font-semibold
            bg-white
            w-fit
            px-2
          "
          >VS6 20073</span
        >
        <div class="flex items-end justify-end">
          <span
            class="
              text-xs
              relative
              bottom-11
              inline-block
              rounded-tl-md rounded-br-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >Tiara Chafing Dish 6Ltrs</span
          >
        </div>
      </div>

      <div class="grid col-span-2 justify-center items-center h-fit my-auto">
        <img
          src="@/assets/chafing/30.webp"
          class="w-screen lg:shadow-lg lg:shadow-slate-900 lg:rounded-lg"
          alt="hexagonal"
        />
      </div>

      <div class="col-span-1 h-fit -mb-9">
        <img
          src="@/assets/chafing/20074.webp"
          alt="20074"
          class="rounded-lg shadow-sm p-5 shadow-neutral-500"
        />

        <span
          class="
            text-xs
            relative
            bottom-6
            rounded-tr-md rounded-bl-md
            bg-opacity-30
            font-semibold
            bg-white
            w-fit
            px-2
          "
          >VS6 20074</span
        >
        <div class="flex items-end justify-end">
          <span
            class="
              text-xs
              relative
              bottom-11
              inline-block
              rounded-tl-md rounded-br-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >Tiara Chafing Dish 8Ltrs</span
          >
        </div>
      </div>
    </section>

    <!-- section 2 -->
    <section class="grid lg:mt-5 lg:grid-cols-2">
      <div class="grid my-auto lg:mx-5 lg:mt-5">
        <img
          src="@/assets/chafing/31.webp"
          class="w-screen lg:shadow-lg  lg:shadow-slate-900  lg:rounded-lg"
          alt="cover"
        />
      </div>

      <div
        class="
          grid grid-cols-2
          md:gap-x-5 md:gap-y-2 md:mx-6
          -mt-2
          gap-x-2
          
          
          pt-5
          lg:pt-0
          justify-center
          items-center
          lg:mx-auto
        "
      >
        <div class="flex flex-col scale-90 -mb-3">
          <img
            src="@/assets/chafing/20201.webp"
            class="rounded-lg h-1/2 p-3 xl:p-5 shadow-sm shadow-neutral-700"
          />
          <span
            class="
              text-sm
              relative
              bottom-6
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20201</span
          >
          <div class="flex items-end justify-end">
            <span
              class="
                text-sm
                relative
                bottom-10
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                opacity-0
                bg-white
                w-fit
                px-2
              "
              >kk</span
            >
          </div>

          <img
            src="@/assets/chafing/20202.webp"
            class="
              rounded-lg
              h-1/2
              p-3
              
              -mt-3
              lg:-mt-3
              shadow-sm shadow-neutral-500
            "
          />
          <span
            class="
              text-sm
              relative
              bottom-6
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20202</span
          >
          <div class="flex items-end justify-end">
            <span
              class="
                text-xs
                relative
                bottom-10
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                opacity-0
                bg-white
                w-fit
                px-2
              "
              >Round Serving Bowl</span
            >
          </div>
        </div>

        <div class="flex flex-col relative -mt-4 lg:mt-0  scale-105 lg:mr-7 mr-4  -mb-3">
          <img
            src="@/assets/chafing/20075.webp"
            class="rounded-lg shadow-sm p-5  shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20075</span
          >
          <div class="flex items-end lg:static absolute top-0 right-0 justify-end">
            <span
              class="
                text-xs
                relative
                lg:bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
                
              "
              >Hanging Garden 6Ltrs</span
            >
          </div>
        </div>
      </div>
    </section>

    <!-- section 3 -->
    <section class="lg:flex lg:w-full first lg:-mt-10 lg:px-5 lg:pb-5">
      <div class="grid justify-center items-center my-auto lg:mr-5 lg:w-1/2">
        <img
          src="@/assets/chafing/20063a.webp"
          class="w-screen lg:shadow-md lg:shadow-slate-900 lg:rounded-lg"
          alt="hexagonal"
        />
      </div>

      <div
        class="
          grid
          lg:w-1/2
          grid-cols-2
          md:gap-x-5 md:mx-6
          lg:mr-5
          gap-x-4
          mx-3
          mt-5
          lg:py-0
          items-center
          justify-center
          lg:mx-auto
        "
      >
     <div class="w-full my-2 lg:my-0  lg:ml-2 scale-110 h-full ">
      <swiper
  class="relative "
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :loop="true"
    :autoplay="{
      delay: 1170,
      disableOnInteraction: false,
    }"
  

    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20055.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20055</span>
    <div class="flex items-end absolute lg:static top-0 right-0 justify-end">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Rect Glass Lid 12Ltrs</span>
</div></div></swiper-slide>
    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20058.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20058</span>
    <div class="flex items-end absolute lg:static top-0 right-0 justify-end">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Rect Gold Glass Lid 12Ltrs</span>
</div></div></swiper-slide>
    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20061.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20061</span>
    <div class="flex items-end absolute lg:static top-0 right-0 justify-end">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Rect Rose Glass Lid 12Ltrs</span>
</div></div></swiper-slide>
    


  </swiper>
  </div>
<div class="w-full my-2 lg:my-0 lg:ml-2 scale-110 h-full ">
      <swiper
  class=""
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :loop="true"
    :autoplay="{
      delay: 1200,
      
      disableOnInteraction: false,
    }"
  

    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
     <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20056.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20056</span>
    <div class="flex items-end absolute lg:static top-0 right-0 justify-end">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Sq. Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>
    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20059.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20059</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0  ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Sq. Gold Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>
<swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20062.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20062</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Sq. Rose Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>


  </swiper>
  </div>
  <div class="w-full my-2 lg:my-0 lg:ml-2 scale-110 h-full ">
      <swiper
  class=""
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :loop="true"
    :autoplay="{
      delay: 1150,
      
      disableOnInteraction: false,
    }"
  

    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
     <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20057.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20057</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Round Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>


<swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20060.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20060</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Round Gold Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>

    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20063.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20063</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Round Rose Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>


  </swiper>
  </div>
  <div class="w-full my-2 lg:my-0 lg:ml-2 scale-110 h-full ">
      <swiper
  class=""
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :loop="true"
    :autoplay="{
      delay: 1100,
      disableOnInteraction: false,
    }"
  

    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20046.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20046</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Rect Glass Lid 12Ltrs</span>
</div></div></swiper-slide>


<swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20049.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20049</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Rect Gold Glass Lid 12Ltrs</span>
</div></div></swiper-slide>

    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20052.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20052</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Rect Rose Glass Lid 12Ltrs</span>
</div></div></swiper-slide>
    


  </swiper>
  </div>

      </div>
    </section>

    <!-- section 3.5 -->
    <section
      class="
      mx-3
      max-w-screen w-fit
        lg:grid-cols-4
        gap-x-4
        md:gap-x-5 md:gap-y-2 md:mx-5
        overflow-hidden
        grid-cols-2 grid items-center justify-center
      "
    >
      <div class="w-fit lg:grid hidden justify-center items-center col-span-2 mr-5 ">
        <img
          src="@/assets/chafing/20071.webp"
          class="rounded-lg px-4 shadow-sm shadow-neutral-500"
        />
        <span
          class="
            text-xs
            relative
            bottom-6
            rounded-tr-md rounded-bl-md
            bg-opacity-30
            font-semibold
            bg-white
            w-fit
            px-2
          "
          >VS6 20071</span
        >
        <div class="flex items-end justify-end">
          <span
            class="
              text-xs
              relative
              bottom-10
              inline-block
              rounded-tl-md rounded-br-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >Ladle</span
          >
        </div>
      </div>
<div class="w-full lg:-ml-2 mt-2 lg:my-0  scale-110 h-full ">
      <swiper
  class="mb-4 lg:mb-0"
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :loop="true"
    :autoplay="{
      delay: 1200,
      disableOnInteraction: false,
    }"
  

    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20047.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20047</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Sq. Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>


<swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20050.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20050</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Sq. Gold Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>

    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20053.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20053</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Sq. Rose Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>
    


  </swiper>
  </div>
<div class="w-full lg:-ml-2 mt-2 lg:my-0 scale-110 h-full ">
      <swiper
  class=""
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :loop="true"
    :autoplay="{
      delay: 1260,
      
      disableOnInteraction: false,
    }"
  

    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20048.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20048</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Round Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>


<swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20051.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20051</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Round Gold Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>

    <swiper-slide> <div   class="flex flex-col scale-90 -mb-3" >
    <img src="@/assets/chafing/20054.webp" class="rounded-lg h-1/2 p-3 xl:p-5   shadow-sm shadow-neutral-500" >
    <span class="text-xs relative bottom-4   rounded-tr-md rounded-bl-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >VS6 20054</span>
    <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
    <span class="text-xs relative lg:bottom-8  inline-block rounded-tl-md rounded-br-md bg-opacity-30 font-semibold  bg-white w-fit px-2" >Round Rose Glass Lid 6.5Ltrs</span>
</div></div></swiper-slide>


  </swiper>
  </div>
      <div class="w-fit grid lg:hidden justify-center items-center col-span-2 ">
        <img
          src="@/assets/chafing/20071.webp"
          class="rounded-lg px-4 shadow-sm shadow-neutral-500"
        />
        <span
          class="
            text-xs
            relative
            bottom-6
            rounded-tr-md rounded-bl-md
            bg-opacity-30
            font-semibold
            bg-white
            w-fit
            px-2
          "
          >VS6 20071</span
        >
        <div class="flex items-end justify-end">
          <span
            class="
              text-xs
              relative
              bottom-10
              inline-block
              rounded-tl-md rounded-br-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >Ladle</span
          >
        </div>
      </div>
    </section>

    <!-- section 4 -->
    <section class="grid lg:mb-12 lg:grid-cols-2">
      <div
        class="
          lg:grid
          hidden
          lg:-mb-7 lg:ml-5
          grid-cols-2
          md:gap-x-5 md:gap-y-2 md:mx-6
          -mt-2
          gap-x-4
          mx-3
          pt-5
          lg:pt-0
          justify-center
          items-center
          lg:mx-auto
        "
      >
        <div class="flex flex-col -mb-3">
          <img
            src="@/assets/chafing/20076.webp"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20076</span
          >
          <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
            <span
              class="
                text-xs
                relative
                lg:bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Hanging Garden 8Ltrs</span
            >
          </div>
        </div>
        <div class="flex flex-col -mb-3">
          <img
            src="@/assets/chafing/20077.webp"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20077</span
          >
          <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
            <span
              class="
                text-xs
                relative
                lg:bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Bubble Chafing Dish 6Ltrs</span
            >
          </div>
        </div>
      </div>

      <div class="grid my-auto justify-center items-center lg:mx-5">
        <img
          src="@/assets/chafing/32.webp"
          class="w-screen shadow-slate-900 shadow-md lg:rounded-lg"
          alt="cover"
        />
      </div>

      <div
        class="
          grid
          
          lg:hidden lg:ml-5
          grid-cols-2
          md:gap-x-5 md:gap-y-2 md:mx-6
          -mt-2
          gap-x-4
          mx-3
          pt-7
          lg:pt-0
          justify-center
          items-center
          lg:mx-auto
        "
      >
        <div class="flex flex-col relative ">
          <img
            src="@/assets/chafing/20076.webp"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20076</span
          >
          <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
            <span
              class="
                text-xs
                relative
                lg:bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Hanging Garden 8Ltrs</span
            >
          </div>
        </div>
        <div class="flex flex-col relative  ">
          <img
            src="@/assets/chafing/20077.webp"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20077</span
          >
          <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
            <span
              class="
                text-[11.5px]
                relative
                lg:bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Bubble Chafing Dish 6 Ltrs</span
            >
          </div>
        </div>
      </div>
    </section>

    <!-- section 5 -->
    <section class="grid lg:-mb-12 -mb-6  lg:grid-cols-2">
      <div class="grid my-auto mt-0 justify-center items-center lg:ml-5">
        <img
          src="@/assets/chafing/20055a.webp"
          class="w-screen lg:rounded-lg"
          alt="VS6 19980"
        />
      </div>

      <div
        class="
          grid grid-rows-2 grid-cols-2
           md:mx-6
           gap-x-4
           gap-y-6
           
          lg:-mt-2
        
          mx-3
          mt-5
          
          lg:pt-0
          justify-center
          items-center
          lg:mx-auto
        "
      >
        <div class="flex flex-col col-span-2 row-span-1 lg:scale-[95%] -mb-3">
          <img
            src="@/assets/chafing/20072.webp"
            class="rounded-lg shadow-sm px-5 lg:-mb-5 lg:mt-3 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20072</span
          >
          <div class="flex items-end justify-end">
            <span
              class="
                text-xs
                relative
                bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Spoon Rest</span
            >
          </div>
        </div>
        <div class="flex relative flex-col -mt-10 col-span-1 lg:scale-[95%] lg:pl-3 -mb-3">
          <img
            src="@/assets/chafing/20068.webp"
            class="rounded-lg shadow-sm p-5 lg:py-0 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              
              
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20068</span
          >
          <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
            <span
              class="
                text-xs
                relative
                lg:bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Rect. Dish TP Cover (Large)</span
            >
          </div>
        </div>
        <div class="flex flex-col relative -mt-10 col-span-1 lg:pr-3 lg:scale-[95%] -mb-3">
          <img
            src="@/assets/chafing/20069.webp"
            class="rounded-lg shadow-sm p-5 lg:py-0 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20069</span
          >
          <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
            <span
              class="
                text-xs
                relative
                lg:bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Rect. Dish with TP Cover</span
            >
          </div>
        </div>
      </div>
    </section>

   <!-- section 6 -->
    <!-- <section class="grid  lg:grid-cols-2">
      <div class="lg:hidden grid my-auto lg:mx-5 lg:mt-5">
        <img
          src="@/assets/chafing/20049a.webp"
          class="w-screen lg:rounded-lg"
          alt="VS6 19980"
        />
      </div>

      <div
        class="
          grid grid-cols-2
          md:gap-x-5 md:gap-y-2 md:mx-6
          -mt-2
          gap-x-2
          
          
          pt-5
          lg:pt-0
          justify-center
          items-center
          lg:mx-auto
        "
      >
        <div class="flex flex-col scale-90 -mb-3">
          <img
            src="@/assets/chafing/20055.webp"
            class="rounded-lg h-1/2 p-3 xl:p-5 shadow-sm shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20055/15</span
          >
          <div class="flex items-end justify-end">
            <span
              class="
                text-xs
                relative
                bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Hexa Serving Bowl</span
            >
          </div>

          <img
            src="@/assets/chafing/20055.webp"
            class="
              rounded-lg
              h-1/2
              p-3
              xl:p-5
              -mt-3
              lg:-mt-3
              shadow-sm shadow-neutral-500
            "
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20016/17</span
          >
          <div class="flex items-end justify-end">
            <span
              class="
                text-xs
                relative
                bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Round Serving Bowl</span
            >
          </div>
        </div>

        <div class="flex flex-col lg:mr-5 mr-2 -mb-3">
          <img
            src="@/assets/chafing/20073.webp"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />
          <span
            class="
              text-xs
              relative
              bottom-4
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20073</span
          >
          <div class="flex items-end justify-end">
            <span
              class="
                text-xs
                relative
                bottom-8
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >2 Tier Stand</span
            >
          </div>
        </div>
      </div>
       <div class="hidden lg:grid my-auto lg:mx-5 lg:mt-5">
        <img
          src="@/assets/chafing/20049a.webp"
          class="w-screen lg:rounded-lg"
          alt="VS6 19980"
        />
      </div>
    </section> -->



        <!-- section 6 on phone -->
    <section class="lg:hidden pt-5 first ">
      <div class="grid justify-center items-center my-auto">
        <img
          src="@/assets/chafing/20066.webp"
          class="w-screen lg:shadow-md lg:shadow-slate-900 lg:rounded-lg"
          alt="cover"
        />
      </div>

      <div
        class="
          grid
          
          grid-cols-2
          md:gap-x-5 md:mx-6
          
          gap-x-4
          mx-3
          mt-5
          lg:py-0
          items-center
          justify-center
          lg:mx-auto
        "
      >
        <div class="relative">
          <img
            src="@/assets/chafing/20070.webp"
            alt="20111"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />

          <span
            class="
              text-xs
              relative
              bottom-6
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20070</span
          >
          <div class="flex items-end justify-end absolute lg:static top-0 right-0 ">
            <span
              class="
                text-[10px]
                relative
                lg:bottom-10
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Round Dish With TP Cover 7Ltrs</span
            >
          </div>
        </div>

        <div class="relative">
          <img
            src="@/assets/chafing/20065.webp"
            alt="20113"
            class="rounded-lg shadow-sm p-5 shadow-neutral-500"
          />

          <span
            class="
              text-xs
              relative
              bottom-6
              rounded-tr-md rounded-bl-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >VS6 20065</span
          >
          <div class="flex items-end absolute lg:static top-0 right-0  justify-end">
            <span
              class="
                text-xs
                relative
                
                inline-block
                rounded-tl-md rounded-br-md
                bg-opacity-30
                font-semibold
                bg-white
                w-fit
                px-2
              "
              >Round Copper Dish 5Ltrs</span
            >
          </div>
        </div>
      </div>
    </section>

    <!-- section 6 on pc  -->
    <section class="lg:grid grid-cols-4 pt-10 pb-10 gap-x-5 px-5 hidden">
      <div class="col-span-1  h-fit -mb-9">
        <img
          src="@/assets/chafing/20070.webp"
          alt="20111"
          class="rounded-lg shadow-sm p-5 shadow-neutral-500"
        />

        <span
          class="
            text-xs
            relative
            bottom-6
            rounded-tr-md rounded-bl-md
            bg-opacity-30
            font-semibold
            bg-white
            w-fit
            px-2
          "
          >VS6 20070</span
        >
        <div class="flex items-end justify-end">
          <span
            class="
              text-[11px]
              relative
              bottom-11
              inline-block
              rounded-tl-md rounded-br-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >Round Dish With TP Cover 7Ltrs</span
          >
        </div>
      </div>

      <div class="grid col-span-2 justify-center items-center h-fit my-auto">
        <img
          src="@/assets/chafing/20066.webp"
          class="w-screen lg:shadow-lg lg:shadow-slate-900 lg:rounded-lg"
          alt="hexagonal"
        />
      </div>

      <div class="col-span-1 h-fit -mb-9">
        <img
          src="@/assets/chafing/20065.webp"
          alt="20074"
          class="rounded-lg shadow-sm p-5 shadow-neutral-500"
        />

        <span
          class="
            text-xs
            relative
            bottom-6
            rounded-tr-md rounded-bl-md
            bg-opacity-30
            font-semibold
            bg-white
            w-fit
            px-2
          "
          >VS6 20065</span
        >
        <div class="flex items-end justify-end">
          <span
            class="
              text-xs
              relative
              bottom-11
              inline-block
              rounded-tl-md rounded-br-md
              bg-opacity-30
              font-semibold
              bg-white
              w-fit
              px-2
            "
            >Round Copper Dish 5Ltrs</span
          >
        </div>
      </div>
    </section>
  </main>
</template>


<script>
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
export default {
  data() {
    return {
      chafing: [
        {
          img: "20018",
          text: "Rect. Serving Bowl",
        },
        {
          img: "20020",
          text: "Oval Serving Bowl",
        },
        {
          img: "20025",
          text: "Bread Basket",
        },
        {
          img: "20026",
          text: "Napkin Holder",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>


<style >


swiper-slide {
transition-timing-function: cubic-bezier(.3,.75,.78,-0.8);}
.swiper-button-prev,
.swiper-button-next {

  @apply text-black px-6 font-extrabold hover:text-red-500 scale-50;
}
</style>